





















































































































































































































import Vue from 'vue';
// import downloadFiles from '@/config/conditions-files';
import { WEBSITE_URL, WEBSITE_PUBLIC_URL } from '@/constant/constants';
import { getConditionsFilesHelper } from '@/helper/functions';
import SignaturePopUpViewer from '@/components/form/signature-pop-up-viewer.vue';


export default Vue.extend({
  name: 'conditions-files',
  props: [
    'response',
    'insuranceKey',
    'investmentFile',
    'roadHaulierFormData',
    'forwardingActivitiesFormData',
    'watchmanFormData',
    'signImage',
    'isAPIObserver',
  ],
  components: {
    SignaturePopUpViewer,
  },
  data() {
    return {
      downloadFiles: [] as any,
      showDownloadFiles: false,
      showInvestmentFile: false,
      WEBSITE_URL,
      WEBSITE_PUBLIC_URL,
      showSignatureModal: false,
    };
  },
  methods: {
    OpenSignatureModal() {
      this.showSignatureModal = true;
    },
    CloseSignatureModal() {
      this.showSignatureModal = false;
    },
    ShowResponse() {
      // console.log(this.response.form_data.customerData.general.uploadedFileDefaultName);
    },
  },
  mounted() {
    this.downloadFiles = getConditionsFilesHelper(this.insuranceKey);
  },
});
